import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';

export function Gallery(){
    const items = window.galleryItems
    const options = {
            ...
                {
                    "layoutParams": {
                        "structure": {
                            "galleryLayout": -1
                        }
                    },
                    "behaviourParams": {
                        "item": {
                            "clickAction": "LINK"
                        }
                    }
                }
        }
    ;

    const eventsListener = (eventName, eventData) => {
      if(eventName == 'ITEM_ACTION_TRIGGERED'){
          window.location.href = eventData.linkUrl
      }
    };

    const container = {
        width: document.querySelector('.container').clientWidth,
        height: document.querySelector('.container').clientHeight
    };
    const scrollingElement = window;
    return (
        <ProGallery
            items={items}
            options={options}
            container={container}
            eventsListener={eventsListener}
            scrollingElement={scrollingElement}
        />
    );
}
